
import script from '/home/circleci/app/packages/layout-localization-event-component/src/entries/index';
import { handleError } from '@fiverr-private/layout-lib/CSRTemplate/handleError';

try {
    const props = window.initialData['LocalizationEvent'];
    script(props);
}
catch(error) {
    handleError(error, "LocalizationEvent", "LocalizationEvent")
}
