/**
 * @constant {string}
 * @desc Translation source in BQ event
 * **/
export const GOOGLE_TRANSLATE_EXTENSION_NAME = 'Google Translate';

/**
 * @constant {Array<string>}
 * @desc Classes added to html when Google Translate is applied
 * **/
export const GOOGLE_TRANSLATE_CLASS_NAMES: string[] = ['translated-ltr', 'translated-rtl'];
