import { getContext } from '@fiverr-private/fiverr_context';
import { getBuyerAssumedLanguage, AssumedLanguageParams } from '@fiverr-private/localization';
import { bigQuery } from '@fiverr-private/obs';
import { GOOGLE_TRANSLATE_CLASS_NAMES, GOOGLE_TRANSLATE_EXTENSION_NAME } from '../constants';

/**
 * Report page localization attributes.
 * @returns {void}
 */
export const reportLocalizationAttributes = () => {
    const { locale, browserLanguage, countryCode, currency, computedLocale } = getContext();
    const assumedLanguageParams: AssumedLanguageParams = {
        countryCode,
        locale,
        browserLanguage,
    };
    const visitorAssumedLanguage = getBuyerAssumedLanguage(assumedLanguageParams);

    bigQuery.send({
        type: 'localization_attributes_per_page',
        group: 'technical_events',
        domain: {
            name: 'Localization',
        },
        localization: {
            experience_locale: locale,
            visitor_assumed_language: visitorAssumedLanguage,
            display_currency: currency,
            formatting_locale: computedLocale,
        },
    });
};

/**
 * Checks whether translation extension is detected.
 * @returns {Boolean} true if translation extension detected.
 */
const isTranslationExtensionDetected = (htmlEl: HTMLElement) =>
    GOOGLE_TRANSLATE_CLASS_NAMES.some((className) => htmlEl.classList.contains(className));

/**
 * Observer the html for Google Translate usage and report it.
 * @returns {void}
 */
export const observeTranslationExtensionUsage = () => {
    if (!window || !window.MutationObserver) {
        return;
    }

    const observer = new MutationObserver((mutationList) => {
        mutationList.forEach(({ target }) => {
            if (target.nodeName !== 'HTML') {
                return;
            }
            const htmlEl = target as HTMLElement;
            if (!isTranslationExtensionDetected(htmlEl)) {
                return;
            }
            observer.disconnect();
            bigQuery.send({
                type: 'page_translated_by_extension',
                group: 'user_action',
                domain: {
                    name: 'Localization',
                },
                localization: {
                    experience_locale: getContext().locale,
                    translation_source: GOOGLE_TRANSLATE_EXTENSION_NAME,
                    language_after_translation: htmlEl.lang,
                },
            });
        });
    });

    const htmlEl = window.document.querySelector('html') as Node;
    observer.observe(htmlEl, {
        attributes: true,
        attributeFilter: ['class'],
    });
};

/**
 * Handles client side initialization
 */
const main = () => {
    reportLocalizationAttributes();
    observeTranslationExtensionUsage();
};

export default main;
